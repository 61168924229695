import "./WelcomeScreen.css"
import {UploadButton} from "./Body.js";


function WelcomeScreen({onUploadClick, onFileChange, inputFile}){
    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            onFileChange(event.dataTransfer.files[0]);
          }
    }

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <div className='drop-area' onDrop={handleDrop} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag}>
            <UploadButton 
                onUploadClick={onUploadClick} 
                onFileChange={(event) => onFileChange(event.target.files[0])}
                inputFile={inputFile}
            />
            <p style={{textAlign:'center'}}>or<br />Drop .json file here</p>
        </div>
    )
}

export default WelcomeScreen;
import React, { createContext, useState } from 'react';

// Create the context
const MusicianContext = createContext();

// Create a provider component
export const MusicianProvider = ({ children }) => {
  const [musicianName, setMusicianName] = useState('');

  return (
    <MusicianContext.Provider value={{ musicianName, setMusicianName }}>
      {children}
    </MusicianContext.Provider>
  );
};

export default MusicianContext;

import "./Page.css";
import Region from "./Region.js";
import { Header, Footer } from "./Body.js";
import { useState } from "react";

const BAR_NUM = 8;
let SHORT = 2;

function ExpandedRegion({ region, onBackgroundClick }) {
  return (
    <div className="expanded-background" onClick={onBackgroundClick}>
      <Region region={region} isExpanded={true} />
    </div>
  );
}

function RegionComponent({ region, arrow }) {
  // TODO: maybe make it more efficient
  const [isShown, setIsShown] = useState(false);
  function onBackgroundClick() {
    setIsShown(false);
  }
  function onRegionClick() {
    setIsShown(true);
  }

  if (arrow == "r") {
    const isShort = BAR_NUM - (region.start % BAR_NUM) < SHORT ? true : false;

    return (
      <div
        style={{
          gridColumnStart: (region.start % BAR_NUM) + 2,
          gridColumnEnd: BAR_NUM + 3,
        }}
      >
        <Number n={region.start + 1} isLight={true} />
        <Region
          region={region}
          arrow={arrow}
          isShort={isShort}
          onRegionClick={onRegionClick}
        />
        {isShown ? (
          <ExpandedRegion
            region={region}
            onBackgroundClick={onBackgroundClick}
          />
        ) : null}
      </div>
    );
  } else if (arrow == "l") {
    const isShort = region.end % BAR_NUM < SHORT ? true : false;

    return (
      <div
        style={{
          gridColumnStart: 1,
          gridColumnEnd: (region.end % BAR_NUM) + 2,
        }}
      >
        <Number />
        <Region
          region={region}
          arrow={arrow}
          isShort={isShort}
          onRegionClick={onRegionClick}
        />
        {isShown ? (
          <ExpandedRegion
            region={region}
            onBackgroundClick={onBackgroundClick}
          />
        ) : null}
      </div>
    );
  } else if (arrow == "rl" || arrow == "lr") {
    return (
      <div style={{ gridColumnStart: 1, gridColumnEnd: BAR_NUM + 3 }}>
        <Number />
        <Region region={region} arrow={arrow} />
      </div>
    );
  } else {
    const isShort = region.end - region.start < SHORT ? true : false;
    let span;
    if (region.end % BAR_NUM == 0) {
      span = BAR_NUM + 2;
    } else {
      span = "span " + (region.end - region.start).toString();
    }
    return (
      <div
        style={{
          gridColumnStart: (region.start % BAR_NUM) + 2,
          gridColumnEnd: span,
        }}
      >
        <Number n={region.start + 1} isLight={true} />
        <Region
          region={region}
          arrow={arrow}
          isShort={isShort}
          onRegionClick={onRegionClick}
        />
        {isShown ? (
          <ExpandedRegion
            region={region}
            onBackgroundClick={onBackgroundClick}
          />
        ) : null}
      </div>
    );
  }
}

function Number({ n, isLight }) {
  if (n == null) return <span>&nbsp;</span>;
  return (
    <span className={isLight ? "number-light" : "number-dark"}>&nbsp;{n}</span>
  );
}

function BarNumbers({ currentPage }) {
  const numbers = [];
  numbers.push(<Number />);
  for (
    let i = (currentPage - 1) * BAR_NUM * 6 + 1;
    i < currentPage * BAR_NUM * 6 - 1;
    i++
  ) {
    if (i % 8 == 0) {
      numbers.push(<Number />);
      numbers.push(<Number />);
      numbers.push(<Number />);
    } else if (i % 4 == 1) {
      numbers.push(<Number n={i} isLight={false} />);
    } else {
      numbers.push(<Number />);
    }
  }
  numbers.push(<Number />);

  return <div className="numbers-grid">{numbers}</div>;
}

function SpawnRegions({ regions }) {
  return <div className="regions-grid">{regions}</div>;
}

function Grid({ regions, currentPage }) {
  return (
    <div className="grid-container">
      <BarNumbers currentPage={currentPage} />
      <SpawnRegions regions={regions} />
    </div>
  );
}

function Page({
  songName,
  songId,
  musicianName,
  bpm,
  tSign,
  sKey,
  regions,
  currentPage,
  totalPages,
  onRightClick,
  onLeftClick,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  onUploadClick,
  onFileChange,
  inputFile,
}) {
  return (
    <div
      className="page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Header
        songName={songName}
        songId={songId}
        musicianName={musicianName}
        bpm={bpm}
        tSign={tSign}
        sKey={sKey}
        onUploadClick={onUploadClick}
        onFileChange={onFileChange}
        inputFile={inputFile}
      />
      <Grid regions={regions} currentPage={currentPage} />
      <Footer
        currentPage={currentPage}
        total={totalPages}
        onRightClick={onRightClick}
        onLeftClick={onLeftClick}
      />
    </div>
  );
}

export { RegionComponent };
export default Page;

import './Body.css';


function RightPointer({onClick}) {
    return <img className='right-pointer' src='./right_arrow.png' alt='' onClick={onClick} />
}
function LeftPointer({onClick}) {
    return <img className='left-pointer' src='./left_arrow.png' alt='' onClick={onClick} />
}


function UploadButton({onUploadClick, onFileChange, inputFile}){
    return (
        <div>
            <button className='upload-button' onClick={onUploadClick}>
                <b>UPLOAD NEW PROJECT</b>
            </button>
            <input
            id='upload' 
            type="file" 
            accept='.json'
            onChange={onFileChange} 
            ref={inputFile}
            style={{display: 'none'}}
            />
        </div>
    );
}

function Header({ songName, songId, musicianName, bpm, tSign, sKey, onUploadClick, onFileChange, inputFile }) {
    return (
        <div className='header-container'>
            <div className='column-container' style={{ textAlign: 'start' }} >
                <p>
                    {songName}
                    <br/>
                    {songId}
                    <br/>
                    {musicianName}
                </p>
            </div>
            <UploadButton onUploadClick={onUploadClick} onFileChange={onFileChange} inputFile={inputFile} />
            <div className='column-container' style={{ textAlign: 'end' }}>
                <p>
                    {bpm + " BPM"}
                    <br />
                    {tSign[0] + "/" + tSign[1]}
                    <br />
                    {sKey}
                </p>
            </div>
        </div>
    );
}

function Footer({ currentPage, total, onRightClick, onLeftClick }) {
    const rightArrow = (currentPage < total) ? <RightPointer onClick={onRightClick} /> : <br/>;
    const leftArrow = (currentPage > 1) ? <LeftPointer onClick={onLeftClick} /> : <br/>;
    return (
        <div className='footer-container'>
            {leftArrow}
            <p>{currentPage + "/" + total}</p>
            {rightArrow}
        </div>
    );
}



export {Header, Footer, UploadButton};